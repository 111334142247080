import React, { useEffect, useState } from "react";
import styles from "./ImportContacts.module.scss";
import { logo_cropped, arrowDownIcon, trash } from "../../assets";
import Button from "../../components/buttons/Button";
import UserService from "../../services/api/userService";
import { useParams, useSearchParams } from "react-router-dom";

const userService = new UserService();
const ImportContacts = () => {
  const params = useParams()
  const [saved, setSaved] = useState(false);

  const [contacts, setContacts] = useState([])
  const [contactsToDelete, setContactsToDelete] = useState([])

  const [searchParams, setSearchParams] = useSearchParams();
  const [isCodeValid, setIsCodeValid] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [recommendations, setRecommendations] = useState([])
  const [selectedContact, setSelectedContact] = useState([])
  const [isDisabled, setIsDisabled] = useState(true);
  const code = searchParams?.get("c")
  const botId = params.botId;
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true)
        if (!contacts?.length) {
          const _contacts = await userService.getUserContacts(params.phoneNumber, code)
          if (_contacts && _contacts.length > 0) {
            setContacts(_contacts)
            setIsCodeValid(true)
          }
          else {
            setContacts([])
            setIsCodeValid(false)
          }
          setIsLoading(false)
        }
      } catch (error) {
        console.log('error', error)
        setIsCodeValid(false)
        setIsLoading(false)
      }
    })()
  }, [])

  const removeContact = (phoneNumber) => {
    if (contacts.length === 1)
      return;
    setIsDisabled(false);
    setContacts((prevContacts) =>
    (prevContacts ? prevContacts.filter((contact) => {
      const hasPhoneNumber = contact.phoneNumber === phoneNumber
      return !hasPhoneNumber;
    }) : [])
    );
    let _contactToDelete = contacts.find(contact => contact.phoneNumber == phoneNumber)
    let _contactsToDelete = [...contactsToDelete, _contactToDelete]
    setContactsToDelete(_contactsToDelete)
  }

  const saveContacts = async () => {
    try {
      if (!contacts?.length) {
        alert('Please select contact')
        return
      }
      await userService.deleteUserContacts(params.phoneNumber, code, contactsToDelete, botId)
      setSaved(true)
    } catch (err) {
      setSaved(false)
      console.error('Error saving contacts:', err);
    }
  }

  const getRecommendedBusinesses = async (phoneNumber) => {
    if (selectedContact === phoneNumber) {
      setSelectedContact(null)
      return;
    } else {
      setSelectedContact(phoneNumber)
    }
    try {
      setIsLoading(true)
      const recommendations = await userService.getRecommendations(phoneNumber)
      if (recommendations.error) {
        setRecommendations([])
      } else {
        setRecommendations(recommendations)
      }
      setIsLoading(false)
    } catch (error) {
      setRecommendations([])
      console.log('error', error)
      setIsLoading(false)
    }
  }

  const RecommendedList = ({ data }) => {
    return (
      <div className={styles.recommendedList}>
        {data?.map((item, index) => (
          <div key={index} className={styles.recommendedListItems}>
            <div className={styles.recommendedListItemWrapper}>
              <p className={styles.recommendedListItem}>{item.businessName}</p>
              <p className={styles.recommendedListItem}>{item.ownerName}</p>
              <p className={styles.recommendedListItem}>{item.location}</p>
              <p className={styles.recommendedListItem}>
                <a href={`tel:${item?.phoneNumber}`}>
                  {item?.phoneNumber}
                </a>
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!isCodeValid && !isLoading) {
    console.log('invalid')
    return (
      <div className={styles.invalidCode}>
        <p>כדי לראות רשימת חברים מעודכנת, יש לחזור לצ'אט ולהמשיך בו את התהליך</p>
      </div>
    )
  }

  // if (isLoading) {
  //   return <div className={styles.invalidCode}>
  //     <h3>Loading...</h3>
  //   </div>
  // }

  return (
    <div className={styles.importContacts}>
      <div className={styles.importContactsContainer}>
        <img src={logo_cropped} alt="logo" className={styles.logo} />
        {!saved ? (
          <>
            <div className={styles.titleContainer}>
              <h3 className={styles.title}>החברים הממליצים שלך</h3>
              <p className={styles.subtitle1} dir="rtl">לחיצה על <img alt="" src={arrowDownIcon} /> תאפשר לך לראות את רשימת המומחים המומלצים של כל חבר או חברה.</p>
              <p className={styles.subtitle2} dir="rtl">לחיצה על <img alt="" src={trash} /> תאפשר לך למחוק חבר או חברה מרשימת החברים הממליצים שלך.</p>
            </div>
            <div className={styles.container}>
              {contacts?.map((contact, index) =>
                <div>
                  <div key={index} className={styles.contactsDetails}>
                    <div className={styles.contacts}>
                      <div className={styles.contactNameContainer} onClick={() => { getRecommendedBusinesses(contact.phoneNumber) }}>
                        <img src={arrowDownIcon} className={`${styles.arrowIcon} ${selectedContact === contact.phoneNumber ? styles.arrowIconRotate : ""}`} alt="" />
                        <span className={styles.name}>{`${contact.name}`}</span>
                      </div>
                    </div>
                    <img disabled={contacts.length === 1} onClick={() => removeContact(contact.phoneNumber)} src={trash} alt="minus" className={`${styles.minus} ${contacts.length === 1 ? styles.disabledMinus : ""}`} />
                  </div>
                  {selectedContact === contact.phoneNumber && (
                    <>
                      {isLoading ? (
                        <div className={styles.loader}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                            width="40"
                            height="40"
                            className={styles.svgLoader}
                          >
                            <circle
                              cx="50"
                              cy="50"
                              r="40"
                              stroke="#3498db"
                              strokeWidth="5"
                              fill="none"
                              strokeDasharray="188.4"
                              strokeDashoffset="0"
                            >
                              <animate
                                attributeName="stroke-dashoffset"
                                from="0"
                                to="-188.4"
                                dur="1s"
                                repeatCount="indefinite"
                              />
                            </circle>
                          </svg>
                        </div>
                      ) : recommendations.length > 0 ? (
                        <RecommendedList data={recommendations} />
                      ) : (
                        <p dir="rtl" className={styles.noRecommendations}>אין המלצות</p>
                      )}
                    </>
                  )}

                </div>
              )}
             { !isDisabled &&
              <Button
                className={`${styles.btn} ${isDisabled ? styles.btnDisabled : ''}`}
                onClick={saveContacts}
              >
                שמירה
              </Button>
            }
            </div>
          </>
        ) : (
          <div className={styles.thankYou}>
            <h1>תודה</h1>
            <p>הפרטים נשמרו בהצלחה</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportContacts;
